import GSAP from 'gsap'
import SplitSomeText from '../helpers/SplitTitles'
import { Animations } from '../helpers/Animations'
import { ColorManager } from '../helpers/Colors'

export default class PageTransition {
	constructor(config) {
		this.element 		= config.element
		this.paragraphs 	= [...this.element.querySelectorAll('p')]

		this.paragraphIDS = {}

		this.randomIndex 	= 0
		this.children 		= []

		this.TL 			= GSAP.timeline()

		this.init()
	}

	init() {

		GSAP.set(this.element, {
			y: '-120%'
		})

		this.paragraphs.forEach(p => {
			this.paragraphIDS[p.getAttribute('data-id')] = p
		})
	}

	splitTitleSetup() {

		this.paragraphs.forEach(p => {
			const splitTitle = new SplitSomeText({ element: p })
			this.children.push(splitTitle)
		})
	}

	getRandomNumber() {
		this.randomIndex = Math.round(Math.random() * (this.paragraphs.length - 1))	
	}

	once() {

		GSAP.set(this.element, {
			y: 0
		})

		return GSAP.to(this.element, {
			delay: 1,
			y: '120%',
			ease: 'circ.inOut',
			duration: 1,
			onComplete: () => {
				window.emitter.emit('pageReady')

				GSAP.set(this.element, {
					y: '-120%'
				})
			}
		})
	}

	beforeLeave(resolve, data) {

		resolve()

		// this.getRandomNumber()

		// this.TL.to(this.element, {
		// 	y: 0,
		// 	duration: 1,
		// 	ease: 'circ.inOut',
		// })

		// this.TL.set(this.paragraphs[this.randomIndex], {
		// 	display: 'unset'
		// })

		// this.TL.fromTo(this.paragraphs[this.randomIndex], {
		// 	y: () => (window.innerHeight * 0.6) + this.paragraphs[this.randomIndex].offsetHeight,
		// 	rotation: -30,
		// }, {
		// 	duration: 1.2,
		// 	ease: Animations.ease.generic,
		// 	y: () => -this.paragraphs[this.randomIndex].offsetHeight * 0.5,
		// 	rotation: () => Math.random() > 0.5 ? -5 : 5,
		// 	onComplete: () => {
		// 		window.scrollTo(0, 0)
		// 		resolve()
		// 	}
		// })
	}
	

	leave(resolve) {

		this.TL.to(this.element, {
			y: 0,
			duration: 1,
			ease: 'circ.inOut',
			onComplete: () => {
				window.scrollTo(0, 0)
				resolve()
			}
		})

		// this.TL.to(this.paragraphs[this.randomIndex], {
		// 	delay: 1.2,
		// 	duration: 0.7,
		// 	ease: Animations.ease.back,
		// 	rotation: 45,
		// 	y: () => (window.innerHeight * 0.6) + this.paragraphs[this.randomIndex].offsetHeight,
		// 	onComplete: () => {
		// 		resolve()
		// 	}
		// })
	}

	beforeEnter(resolve, data) {
		/** here we compare and grab the correct card by it's ID */
		const nextId = data.next.container.getAttribute('data-id')

		this.TL.set(this.paragraphIDS[nextId], {
			display: 'unset'
		})

		this.TL.fromTo(this.paragraphIDS[nextId], {
			y: () => (window.innerHeight * 0.6) + this.paragraphIDS[nextId].offsetHeight,
			rotation: -30,
		}, {
			duration: 1.2,
			ease: Animations.ease.generic,
			y: () => -this.paragraphIDS[nextId].offsetHeight * 0.5,
			rotation: () => Math.random() > 0.5 ? -5 : 5,
		})

		this.TL.to(this.paragraphIDS[nextId], {
			delay: 1.2,
			duration: 0.7,
			ease: Animations.ease.back,
			rotation: 45,
			y: () => (window.innerHeight * 0.6) + this.paragraphIDS[nextId].offsetHeight,
			onComplete: () => {
				resolve()
			}
		})
	}

	after() {

		this.TL.to(this.element, {
			// delay: 0.3,
			y: '100%',
			ease: 'circ.inOut',
			duration: 1,
			onComplete: () => {
				window.emitter.emit('pageReady')

				GSAP.set(this.element, {
					y: '-120%'
				})
			}
		})
	}
}