import Lenis from '@studio-freight/lenis'
import GSAP from 'gsap'

export default class SmoothScroll {
	constructor() {
		this.lenis = new Lenis()

		this.rafCallback = this.rafCallback.bind(this)

		this.init()
	}

	init() {
		GSAP.ticker.add(this.rafCallback)
		GSAP.ticker.lagSmoothing(0)
	}

	rafCallback(time) {
		this.lenis.raf(time * 1000)
	}

	scrollToTop(pos, immediate = false) {
		this.lenis.scrollTo(pos, { immediate: immediate })
	}

	unmount() {
		GSAP.ticker.remove(this.rafCallback)
		this.lenis.destroy()
	}
}