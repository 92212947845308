import GSAP from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default class Brands {
	constructor(el) {
		this.element 		= el

		this.selectTag 		= this.element.querySelector('.c-Brands__select')
		this.titles 		= [...this.element.querySelectorAll('.c-Brands__titles__wrapper')]
		this.brands 		= [...this.element.querySelectorAll('.c-Brands__brands')]

		/**
		 * CUSTOM SELECT
		 */
		this.dropdownButton 	= this.element.querySelector('.c-Brands__dropdown__button')
		this.dropdownButtonText = this.element.querySelector('.c-Brands__dropdown__button__text')
		this.dropdownList 		= this.element.querySelector('.c-Brands__dropdown__list')
		this.dropdownItems 		= [...this.element.querySelectorAll('.c-Brands__dropdown__list__item a')]

		this.currentIndex 	= 0
		this.isExpanded 	= false

		this.updateContent 	= this.updateContent.bind(this)
		this.handleState 	= this.handleState.bind(this)

		this.itemClickHandler = this.onItemEvent.bind(this)
	}

	init() {

		this.setupStyles()
		this.onEvent()
	}

	setupStyles() {
		this.brands.forEach(brand => {
			brand.style.display = 'none'
		})

		this.brands[this.currentIndex].style.display = 'block'

		this.titles.forEach(t => {
			const spans = [...t.querySelectorAll('span')]

			spans[this.currentIndex].style.display = 'unset'
		})
	}

	handleState() {

		if(this.isExpanded) {
			this.dropdownList.style.display  = 'none'
		} else {
			this.dropdownList.style.display  = 'block'
		}

		this.isExpanded = !this.isExpanded
	}

	onEvent() {
		this.dropdownButton.addEventListener('click', this.handleState)

		this.dropdownItems.forEach((el, idx) => {
			el.addEventListener('click', this.itemClickHandler)
		})
	}

	updateContent(idx) {
		/** UPDATE THE CURRENTLY SELECTED ITEM
		* TRACK THE CURRENT INDEX SO WE DON'T NEED A FOREACH LOOP
		*/
		this.titles.forEach(t => {
			const spans = [...t.querySelectorAll('span')]

			spans[this.currentIndex].style.display = 'none'
			spans[idx].style.display = 'unset'
		})

		this.brands[this.currentIndex].style.display = 'none'
		this.brands[idx].style.display = 'block'

		/**
		 * UPDATE THE CURRENT INDEX TO BE THE SELECTED INDEX
		 */
		this.currentIndex = idx

		ScrollTrigger.refresh()
	}
	
	onItemEvent(e) {

		e.preventDefault()

		const idx = [...this.dropdownItems].indexOf(e.currentTarget)
	
		this.updateContent(idx)
		this.dropdownButtonText.textContent = this.dropdownItems[idx].textContent
		this.dropdownList.style.display  = 'none'
		this.isExpanded = false
	}

	offEvent() {
		this.dropdownButton.removeEventListener('click', this.handleState)

		this.dropdownItems.forEach(el => {
			el.removeEventListener('click', this.itemClickHandler)
		})
	}

	unmount() {

		this.offEvent()
	}
}
