import GSAP from 'gsap'
import { Animations } from '../helpers/Animations'
import SplitSomeText from '../helpers/SplitTitles'

export default class Titles {
	constructor(el) {
		this.element 		= el

		this.titles 		= [...this.element.querySelectorAll('.c-Titles__item')]
		this.titleStore 	= []

		/** WE WILL HAVE LOADS OF EVENTS
		 * WE WILL NEED TO ADD > THEN REMOVE
		 * SO WE NEED TO SIMPLIFY TO PROCESS
		 * { 1. eventName: 2. methodName }
		 */
		this.eventMethodMap = {
			'standardTitleIn': 'standardIn',
			'standardTitleOut': 'standardOut',
			'standardTitleReset': 'standardReset',
			'largeTitleIn': 'largeIn',
			'largeTitleOut': 'largeOut',
			'largeTitleReset': 'largeReset',
			'stackerTitleUpdate': 'updateStackerPositions'
		}

		this.eventHandlers 	= {}

		this.snap 					= GSAP.utils.snap(100)
	}
  
	init() {

		this.titles.forEach(title => {
			const dataOptions	= title.getAttribute('data-options')
			const parsedData 	= JSON.parse(dataOptions)

			if(parsedData.style === 'standard' || parsedData.style === 'large' || parsedData.style === 'arc') {
				this.titleStore.push(new SplitSomeText({ element: title }))
			} 
			
			else if (parsedData.style === 'stacker') {

				this.getStackerElements(title)
			}
		})

		this.addEvents()

		// window.emitter.on('showTitles', () => {
		// 	GSAP.to(this.element, {
		// 		duration: 0.3,
		// 		autoAlpha: 1
		// 	})
		// })

		// window.emitter.on('hideTitles', () => {
		// 	GSAP.set(this.element, {
		// 		autoAlpha: 0
		// 	})
		// })
	}

	addEvents() {

		for (const eventName in this.eventMethodMap) {
			if (this.eventMethodMap.hasOwnProperty(eventName)) {

			  	const methodName = this.eventMethodMap[eventName]
	
				const handler = (data) => {
					this[methodName](data.index)
				}

			 	window.emitter.on(eventName, handler)
			 	this.eventHandlers[eventName] = handler
			}
		}
	}

	removeEvents() {
		for (const eventName in this.eventHandlers) {

			if (this.eventHandlers.hasOwnProperty(eventName)) {

				const handler = this.eventHandlers[eventName]
				window.emitter.off(eventName, handler)
				delete this.eventHandlers[eventName]
			}
		}
	}

	/** STANDARD */
	standardIn(idx) {
		this.titles[idx].classList.add('active-in')
		this.titles[idx].classList.remove('active-out')
	}
	/** STANDARD */
	standardOut(idx) {
		this.titles[idx].classList.remove('active-in')
		this.titles[idx].classList.add('active-out')
	}
	/** STANDARD */
	standardReset(idx) {
		this.titles[idx].classList.remove('active-in')
	}

	/** Large */
	largeIn(idx) {
		this.titles[idx].classList.add('active-in')
		this.titles[idx].classList.remove('active-out')
	}
	/** Large */
	largeOut(idx) {
		this.titles[idx].classList.remove('active-in')
		this.titles[idx].classList.add('active-out')
	}
	/** Large */
	largeReset(idx) {
		this.titles[idx].classList.remove('active-in')
	}

	getStackerElements(title) {
		this.stackerItems = [...title.querySelectorAll('.fx')]

		GSAP.set(this.stackerItems, {
			yPercent: i => (100) + i * 100,
		})
	}

	updateStackerPositions(idx, direction) {

		GSAP.to(this.stackerItems, {
			duration: 1,
			ease: 'expo.out',
			overwrite: true,
			yPercent: i => ((-idx * 100) + i * 100),
		})
	}

	unmount() {
		this.titleStore.forEach(title => {
			title.destroy()
		})

		this.titleStore = []

		this.removeEvents()
	}
}
