import GSAP from 'gsap'
import SplitSomeText from '../helpers/SplitTitles'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

GSAP.registerPlugin(ScrollTrigger)

export default class KeyFindings {
	constructor(el) {
		this.element 		= el

		this.tl 			= GSAP.timeline()
		this.mmStacker 		= GSAP.matchMedia()
		this.STInstance 	= null

		this.paragraph 			= [...this.element.querySelectorAll('.c-KeyFindings__content__paragraph p')]
		this.elementsToAnimate 	= []
		this.splits 			= []

		this.dataOptions	= this.element.getAttribute('data-options')
		this.parsedData 	= JSON.parse(this.dataOptions)

		if(this.parsedData.type === 'stacker') {
			this.cards 				= [...this.element.querySelectorAll('.c-KeyFindings__card')]
			this.cardHeight 		= this.cards[0].offsetHeight
			this.setCardPositions 	= this.setCardPositions.bind(this)

		} else if(this.parsedData.type === 'flip') {
			this.flipCards 			= [...this.element.querySelectorAll('.c-KeyFindings__flip')]
			this.handleFlip 		=  this.handleFlip.bind(this)
		}
	}

	init() {

		if(this.parsedData.type === 'stacker') {

			this.mmStacker.add({
				isDesktop: `(min-width: 768px)`,
				isMobile: `(max-width: 767px)`
			}, (context) => {

				let { isDesktop, isMobile } = context.conditions

				if(isDesktop) {
					this.setCardPositions()
					this.setupStackerScrollTrigger()
				}
	
				return () => { 
					// clean up stuff that is run when breakpoint no
					// longer matches
					if(isDesktop) {
	
						if(this.STInstance) {
							this.STInstance.kill()
							this.tl.kill()
						}

						GSAP.set(this.cards, { 
							clearProps: true
						})

						ScrollTrigger.removeEventListener("refresh", this.setCardPositions)
					}
				}
			})

		} else if(this.parsedData.type === 'flip') {

			this.flipCards.forEach(btn => {
				btn.addEventListener('click', this.handleFlip)
			})
		}

		this.STInstance = ScrollTrigger.create({
			trigger: this.element,
			start: 'top bottom-=100px',
			once: true,
			onEnter: () => {
				this.element.classList.add('in-view')
			}
		})
	}


	handleFlip(e) {

		const clickedCard = e.currentTarget

		clickedCard.classList.toggle('active')
	}

	setCardPositions() {

		this.tl.clear()

		this.cardHeight = this.cards[0].offsetHeight

		this.cards.forEach((card, index) => {
			if(index > 0){
				// increment y value of each card by cardHeight
				GSAP.set(card, { 
					y: (index * this.cardHeight) + 100,
					rotation: -20
				})
				// animate each card back to 0 (for stacking)
				this.tl.to(card, {
					y:0,
					duration: index * 0.7,
					rotation: 0,
					ease: 'none'
				}, 0)
			}
		})
	}

	setupStackerScrollTrigger() {

		this.STInstance = ScrollTrigger.create({
			trigger: this.element,
			start: 'top top+=70px',
			pin: true,
			end: ()=>`+=${(this.cards.length * this.cardHeight)}`,
			scrub: true,
			animation: this.tl,
			invalidateOnRefresh: true
		})

		ScrollTrigger.addEventListener("refresh", this.setCardPositions)
	}

	removeEvents() {

		if(this.parsedData.type === 'flip') {
			this.flipCards.forEach(btn => {
				btn.removeEventListener('click', this.handleFlip)
			})
		}

		if(this.parsedData.type === 'stacker') {
			ScrollTrigger.removeEventListener("refresh", this.setCardPositions)
		}
	}

	unmount() {
		this.removeEvents()

		if(this.STInstance) this.STInstance.kill()
		if(this.tl) this.tl.kill()

		if(this.mmStacker) { 
			this.mmStacker.revert()
			this.mmStacker = null
		}

		this.splits.forEach(el => {
			el.destroy()
		})
	}
}
