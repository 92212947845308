import GSAP from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

GSAP.registerPlugin(ScrollTrigger)

import Experience from '../main'

export default class TimelineY {
	constructor(el) {
		this.element 	= el

		this.experience 	= new Experience()
		this.resources		= this.experience.resources
		this.camera 		= this.experience.camera.instance
		this.smoothscroll 	= this.experience.smoothScroll

		this.tl				= null
		this.STInstance		= null
		this.canUpdate 		= false
	}

	init() {

		this.setupScrollTrigger()
	}

	setupScrollTrigger() {

		this.tl = GSAP.timeline()

		this.tl.to(this.camera.position, {
			ease: 'none',
			y: () => `-=${(this.element.offsetHeight + window.innerHeight)}`,
		})

		this.STInstance = ScrollTrigger.create({
			trigger: this.element,
			start: 'top bottom',
			end: 'bottom top',
			invalidateOnRefresh: true,
			scrub: true,
			animation: this.tl,
			// onEnter: () => {
			// 	this.canUpdate = true
			// },
			// onLeave: () => {
			// 	this.canUpdate = false
			// },
			// onEnterBack: () => {
			// 	// this.canUpdate = true
			// }
		})
	}

	update() {
		if(this.canUpdate) {
			//this.camera.position.y = -this.smoothscroll.lenis.animatedScroll
		}
	}

	unmount() {
		GSAP.set(this.camera.position, {
			y: 0
		})

		if(this.STInstance) this.STInstance.kill()
		if(this.tl) this.tl.kill()
	}
}