import  { VideoTexture, MeshBasicMaterial, Mesh } from 'three'
import GSAP from 'gsap'

import Experience from '../main'
import { MediaAnimations } from './MediaAnimations'

export default class VideoObject {
	constructor(config) {
		this.element		= config.element
		this.source 		= config.source
		this.geometry 		= config.geometry
		this.video			= config.video
		this.style 			= config.style
		this.group 			= config.group || false
		this.index 			= config.index
		
		this.experience 	= new Experience()
		this.camera 		= this.experience.camera.instance
		this.sizes 			= this.experience.sizes
		this.scene 			= this.experience.scene

		this.rotationOffset = 0

		this.createVideoTexture()

		this.resize()
	}


	createVideoTexture() {

		this.videoTexture = new VideoTexture(this.video)

		this.videoMaterial = new MeshBasicMaterial({
			map: this.videoTexture,
			transparent: true
		})

		this.mesh = new Mesh(this.geometry, this.videoMaterial)
		
		if(this.group) {
			this.videoMaterial.opacity = 0
			this.group.add(this.mesh)
		} else {
			this.scene.add(this.mesh)
		}
	}

	playVideo() {
		this.video.play()
	}

	pauseVideo() {
		this.video.pause()
	}

	scale() {
		this.mesh.scale.set(this.bounds.width, this.bounds.height, 1)
	}

	posX() {
		this.mesh.position.x = (-this.sizes.width * 0.5) + (this.mesh.scale.x * 0.5) + this.bounds.left
	}

	posY(y = 0) {
		this.mesh.position.y = (((this.sizes.height * 0.5) - (this.mesh.scale.y * 0.5) - this.bounds.top) + y)
	}

	skew() {
		MediaAnimations.skew(this.mesh, this.camera, this.sizes, this.rotationOffset)
	}

	arc() {
		MediaAnimations.arc(this.mesh, this.camera, this.sizes, this.rotationOffset, this.bounds)
	}

	stacker() {
		MediaAnimations.stacker(this.mesh, this.camera, this.sizes, this.rotationOffset)
	}

	show() {

		GSAP.to(this.videoMaterial, {
			opacity: 1
		})
	}

	hide() {
		GSAP.to(this.videoMaterial, {
			opacity: 0
		})
	}

	update() {

		if(this.style === 'skew') {
			this.skew()
		} else if(this.style === 'arc') {
			this.arc()
		} else if(this.style === 'stacker') {
			this.stacker()
		}
	}

	resize() {
		this.bounds 		= this.element.getBoundingClientRect() 

		this.scale()
		this.posX()
		this.posY(this.camera.position.y)
	}

	destroy() {

		this.videoTexture.dispose()
		this.videoMaterial.dispose()
		this.scene.remove(this.mesh)
	}
}
