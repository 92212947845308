import GSAP from 'gsap'

export default class Example {
	constructor(el) {
		this.element 	= el

		this.resize = this.resize.bind(this)
	}
  
	/**
	 * called automatically
	 * */
	init() {

		// here we're showing how to attached an event to the emitter
		window.emitter.on('windowResized', this.resize)
	}

  	/**
	 * called automatically
	 * */
	unmount() {

		// here we're showing how to remove an event from the emitter
		window.emitter.off('windowResized', this.resize)
	}

	/**
	 * Add all the methods you need after
	 * */
	resize() {
		console.log('resized from event emitter')
	}
}
