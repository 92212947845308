import { TextureLoader, SRGBColorSpace } from 'three'

export default class Resources {
	constructor(sources, videoContainer) {

		this.sources 			= sources
		this.videoContainer 	= videoContainer

		// Setup
		this.items 		= {}
		this.loaders 	= {}
		this.toLoad 	= this.sources.length
		this.loaded 	= 0

		this.percentage = 0

		this.setLoaders()
		this.startLoading()
	}

	setLoaders() {
		this.loaders.textureLoader = new TextureLoader()
	}

	startLoading() {

		for(const source of this.sources) {

			if(source.type === 'image') {
				this.loaders.textureLoader.load(source.path, (file) => {
					this.sourceLoaded(source, file)
				})
			} else if(source.type === 'flip') {
				this.loaders.textureLoader.load(source.path, (file) => {
					file.colorSpace = SRGBColorSpace
					this.sourceLoaded(source, file)
				})
			} else if (source.type === 'video') {
				this.createVideo(source)
			}
		}
	}

	createVideo(source) {
		const video 		= document.createElement('video')

		video.src 			= source.path

		// /** DEFAULT STUFF APPLIED TO DOM ELEMENT */
		video.autoplay 		= true
		video.loop 			= true
		video.muted 		= true
		video.playsInline 	= false
		video.crossOrigin 	= 'anonymous'
		video.setAttribute('playsinline', 'playsinline')

		this.videoContainer.appendChild(video)

		video.addEventListener('loadedmetadata', () => {
			this.sourceLoaded(source, video)
		})
	}

	sourceLoaded(source, file) {
		this.items[source.name] = file

		//console.log(this.loaded, source.name)
		this.loaded++

		if(this.loaded === this.toLoad) {
			window.emitter.emit('sourcesLoaded')
			//console.log('finished')
		}
	}
}