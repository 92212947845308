import { PerspectiveCamera } from 'three'
import Experience from '../main'

import GSAP from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

GSAP.registerPlugin(ScrollTrigger)

export default class Camera {
    constructor() {
        this.experience = new Experience()
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.canvas = this.experience.canvas

        this.cameraPosition = window.innerHeight

        this.setInstance()
    }

    setInstance() {

        this.newFOV = 2 * Math.atan((this.sizes.height / 2) / this.cameraPosition) * (180 / Math.PI)

		this.instance = new PerspectiveCamera(this.newFOV, this.sizes.width / this.sizes.height, 10, 4000)
		this.instance.position.z = this.cameraPosition

        this.scene.add(this.instance)
    }

    resize() {
        this.cameraPosition = window.innerHeight

        this.instance.aspect = this.sizes.width / this.sizes.height

        this.newFOV = 2 * Math.atan((this.sizes.height / 2) / this.cameraPosition) * (180 / Math.PI)
		this.instance.fov = this.newFOV

        this.instance.updateProjectionMatrix()
    }

    update() {
        
    }
}