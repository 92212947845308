import GSAP from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default class CircleGraph {
	constructor(el) {
		this.element 	= el

		this.graphs 	= [...this.element.querySelectorAll('.c-CircleGraph__item__graph')]
	}

	init() {

		this.addScrollTriggers()
	}

	addScrollTriggers() {

		this.scaleTL = GSAP.timeline()

		this.scaleTL.fromTo(this.graphs, {
			scale: 0.2
		}, {
			scale: 1,
			ease: 'none',
			stagger: 0.2,
			scrollTrigger: {
				trigger: this.graphs[0],
				start: 'top bottom',
				endTrigger: this.graphs[3],
				scrub: true,
				end: 'bottom center+=200px',
			}
		})
	}

	removeScrollTriggers() {

		if(this.scaleTL) this.scaleTL.kill()
	}

	unmount() {

		this.removeScrollTriggers()
	}
}
