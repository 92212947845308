import { Raycaster, Vector2 } from 'three'
import GSAP from 'gsap'
import { Animations } from '../helpers/Animations'

import Experience from '../main'

export default class RaycastHandler {
	constructor(config) {

		this.experience 			= new Experience()
		this.sizes 					= this.experience.sizes
		this.scene 					= this.experience.scene
		this.camera 				= this.experience.camera.instance

		this.raycaster 				= new Raycaster()

		this.intersects 			= null
		this.currentMousedObject 	= null
		this.prevOb 				= null
		// this.isExpanded 			= false

		this.mouse = {
			x: 0,
			y: 0
		}

		this.touch = {
			x: 0,
			y: 0
		}
	}

	handleMouseClick() {
		if(this.intersects.length > 0) {

			//console.log(this.intersects[0].object.parent.parent.state)

			if(this.intersects[0].object.parent.parent.state == 'false') {

				GSAP.to(this.intersects[0].object.parent.rotation, {
					y: Math.PI,
					ease: Animations.ease.circInOut,
					duration: Animations.timing.generic,
				})

				this.intersects[0].object.parent.parent.state = 'true'

				//console.log(this.intersects[0].object.parent.parent.state)

			} else if(this.intersects[0].object.parent.parent.state == 'true') {

				GSAP.to(this.intersects[0].object.parent.rotation, {
					y: 0,
					ease: Animations.ease.circInOut,
					duration: Animations.timing.generic,
				})

				this.intersects[0].object.parent.parent.state = 'false'
			}
		}
	}

	handleMouseMove(e) {

		this.mouse.x = (e.clientX / this.sizes.width) * 2 - 1
		this.mouse.y = -(e.clientY / this.sizes.height) * 2 + 1

		this.raycaster.setFromCamera(this.mouse, this.camera)

		this.intersects = this.raycaster.intersectObjects(window.objectsToRaycast)

		if(this.intersects.length > 0) {

			this.currentMousedObject 	= this.intersects[0].object

			if(this.currentMousedObject !== this.prevMousedObject) {
				this.prevMousedObject 	= this.currentMousedObject

				// console.log('hovered', this.intersects[0].object.parent)

				this.toggleBodyClass()
			}

		} else if(this.prevMousedObject !== null) {

			this.toggleBodyClass()
			// console.log('not hovered')

			this.prevMousedObject 	= null
		}
	}

	toggleBodyClass() {
		document.body.classList[this.intersects.length > 0 ? 'add' : 'remove' ]('raycasting')
	}

	destroy() {

		document.body.classList.remove('raycasting')

		this.mouse 		= null
		this.raycaster 	= null
	}
}