import GSAP from 'gsap'
import ObserveElement from '../helpers/ObserveElement'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default class FlexibleParagraph {
	constructor(el) {
		this.element 			= el

	}

	init() {

		// this.fades.forEach(f => {
		// 	this.observes.push(new ObserveElement(f))
		// })
	}

	unmount() {
		// this.observes.forEach(o => {
		// 	o.unobserve()
		// })

		// if(this.STInstance) this.STInstance.kill()
	}
}
