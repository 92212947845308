import GSAP from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default class BarGraph {
	constructor(el) {
		this.element 	= el

		this.dataOptions	= this.element.getAttribute('data-options')
		this.parsedData 	= JSON.parse(this.dataOptions)

		this.graphs 		= [...this.element.querySelectorAll('.c-BarGraph__item__graph')]
		this.items 			= [...this.element.querySelectorAll('.c-BarGraph__item')]

		this.gsapTL 		= null
	}

	init() {

		if(this.parsedData.type === 'horizontal') {
			this.setupHorizontalAnimation()
		} else if(this.parsedData.type === 'vertical') {
			this.setupVerticalAnimation()
		}
	}

	setupHorizontalAnimation() {

		this.graphs.forEach(g => {
			const widthAim = g.getAttribute('data-width')

			this.gsapTL = GSAP.fromTo(g, {
				'--offset': '0%'
			}, {
				'--offset': `${widthAim}%`,
				ease: 'none',
				scrollTrigger: {
					trigger: g,
					start: 'top bottom',
					scrub: true,
					invalidateOnRefresh: true,
					end: () => `+=${g.offsetHeight * 2}`
				}
			})
		})
	}

	setupVerticalAnimation() {

		this.mm = GSAP.matchMedia()

		this.mm.add({
			isDesktop: `(min-width: 768px)`,
			isMobile: `(max-width: 767px)`
		}, (context) => {

			let { isDesktop, isMobile } = context.conditions

			if(isDesktop) {
				this.verticalStaticTrigger()
			}

			if(isMobile) {
				this.verticalMobileFunctionality()
			}

			return () => { 
				// clean up stuff that is run when breakpoint no
				// longer matches
				// meaning kill stuff you had on desktop, ready for mobile stuff
				if(isDesktop) {

					if(this.STInstance) {
						this.STInstance.kill()
						this.element.classList.remove('active')
					}

				}
	
				if(isMobile) {
	
					if(this.gsapTL) {
						this.gsapTL.kill()
					}
				}
			}
		})
	}

	verticalMobileFunctionality() {

		this.graphs.forEach((g, idx) => {
			const widthAim = g.getAttribute('data-width')

			this.gsapTL = GSAP.fromTo(g, {
				'--offset': '0%'
			}, {
				'--offset': `${widthAim}%`,
				ease: 'none',
				scrollTrigger: {
					trigger: g,
					start: 'top bottom',
					scrub: true,
					invalidateOnRefresh: true,
					end: () => `+=${g.offsetHeight * 2}`
				}
			})
		})
	}

	verticalStaticTrigger() {

		this.graphs.forEach((g, idx) => {
			const widthAim = g.getAttribute('data-width')
			g.style.setProperty('--offset', `${widthAim}%`)
		})

		this.STInstance = ScrollTrigger.create({
			trigger: this.element,
			start: 'bottom bottom+=200px',
			end: 'bottom top',
			invalidateOnRefresh: true,
			onEnter: () => {
				this.element.classList.add('active')
			},
			onLeaveBack: () => {
				this.element.classList.remove('active')
			}
		})
	}

	unmount() {
		if(this.STInstance) this.STInstance.kill()
		if(this.gsapTL) this.gsapTL.kill()
	}
}
