import GSAP from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

GSAP.registerPlugin(ScrollTrigger)

export default class BrandCategoryList {
	constructor(el) {
		this.element 		= el

		this.container 		= this.element.querySelector('.c-BrandCategoryList__container')
		this.imageWrapper 	= this.element.querySelector('.c-BrandCategoryList__images')
		this.images 		= [...this.element.querySelectorAll('.c-BrandCategoryList__image')]
		this.links 			= [...this.element.querySelectorAll('a')]

		this.header 		= document.querySelector('.c-Header')
		this.STInstance 	= null

		this.mouse 			= { current: 0, target: 0, lerp: 0.1 }
		this.isActive 		= false

		if(!window.isTouchscreen) {
			this.handleMouseMove 	= this.handleMouseMove.bind(this)
			this.showImages			= this.showImages.bind(this)
			this.hideImages			= this.hideImages.bind(this)
			this.activate 			= this.activate.bind(this)
			this.deActivate 		= this.deActivate.bind(this)
		}
	}
	
	init() {

		this.isActive = true

		this.setupScrolltrigger()

		if(!window.isTouchscreen) {
			this.container.addEventListener("mousemove", this.handleMouseMove)
			this.container.addEventListener("mouseenter", this.showImages)
			this.container.addEventListener("mouseleave", this.hideImages)

			this.links.forEach((l, idx) => {
				l.addEventListener('mouseenter', this.activate)
				l.addEventListener('mouseleave', this.deActivate)
			})
		}

	}

	handleMouseMove(e) {
		this.mouse.target = e.clientY
	}

	setupScrolltrigger() {

		this.STInstance = ScrollTrigger.create({
			trigger: this.element,
			start: 'top top',
			end: 'bottom top',
			onEnter: () => {
				this.header.classList.add('has-gradient')
			},
			onLeaveBack: () => {
				this.header.classList.remove('has-gradient')
			},
		})
	}

	update() {

		if(this.isActive) {
			this.mouse.current = GSAP.utils.interpolate(this.mouse.current, this.mouse.target, this.mouse.lerp)
			this.imageWrapper.style.transform = `translateY(${this.mouse.current}px)`
		}
	}

	showImages() {
		GSAP.to(this.imageWrapper, {
			opacity: 1,
			duration: 0.3
		})
	}

	hideImages() {
		GSAP.to(this.imageWrapper, {
			opacity: 0,
			duration: 0.3
		})
	}

	activate(e) {
		const idx = this.links.indexOf(e.target)
		this.images[idx].dataset.status = 'active'
	}

	deActivate(e) {
		const idx = this.links.indexOf(e.target)
		this.images[idx].dataset.status = 'inactive'
	}

	unmount() {

		this.isActive = false

		if(this.STInstance) this.STInstance.kill()

		if(!window.isTouchscreen) {

			this.mouse = null

			this.container.removeEventListener("mousemove", this.handleMouseMove)
			this.container.removeEventListener("mouseenter", this.showImages)
			this.container.removeEventListener("mouseleave", this.hideImages)

			this.links.forEach((l, idx) => {
				l.removeEventListener('mouseenter', this.activate)
				l.removeEventListener('mouseleave', this.deActivate)
			})
		}
	}
}
