
import { WebGLRenderer } from 'three'
import Experience from '../main.js'

export default class Renderer {
	constructor() {
		this.experience = new Experience()
		this.canvas 	= this.experience.canvas
		this.sizes 		= this.experience.sizes
		this.scene 		= this.experience.scene
		this.camera 	= this.experience.camera

		this.setInstance()
	}

	setInstance() {

		this.instance = new WebGLRenderer({
			canvas: this.canvas,
			antialias: true,
			alpha: true
		})

		this.instance.setSize(this.sizes.width, this.sizes.height)
		this.instance.setPixelRatio(this.sizes.pixelRatio)
		// this.instance.outputColorSpace = SRGBColorSpace
		// this.instance.physicallyCorrectLights = true
	}

	resize() {
		this.instance.setSize(this.sizes.width, this.sizes.height)
		this.instance.setPixelRatio(this.sizes.pixelRatio)
	}

	update() {
		this.instance.render(this.scene, this.camera.instance)
	}
}