import  { PlaneGeometry } from 'three'
import GSAP from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import SplitSomeText from '../helpers/SplitTitles'

GSAP.registerPlugin(ScrollTrigger)

import Experience from '../main'
import ImageObject from '../webgl/ImageObject'
import VideoObject from '../webgl/VideoObject'

export default class TimelineYGroup {
	constructor(el) {
		this.element 	= el

		this.experience 	= new Experience()
		this.resources		= this.experience.resources
		this.camera 		= this.experience.camera.instance
		this.smoothscroll 	= this.experience.smoothScroll

		this.media			= [...this.element.querySelectorAll('.c-TimelineY__item')]
		this.titles			= this.element.querySelector('.c-TimelineY__titles')
		this.titlesHeading	= this.titles.querySelector('h1')
		this.isTitleStacker = this.titles.classList.contains('c-TimelineY__titles--stacker')

		this.tl 			= null
		this.canUpdate		= false
		this.STInstance		= null

		// this.globalTimeout 	= 600

		this.dataOptions	= this.element.getAttribute('data-options')
		this.parsedData 	= JSON.parse(this.dataOptions)
		this.resize 		= this.resize.bind(this)

		this.scroll = {
			current: 0,
			target: 0,
			lerp: 0.1
		}
	}

	init() {

		this.createGroup()
		this.setupScrollTrigger()

		this.setupHeadingAnimation()

		// if(this.parsedData.style === 'standard' || this.parsedData.style === 'arc') {
		// 	//this.updateStandardTitle()
		// } else if(this.parsedData.style === 'large') {
		// 	//this.updateLargeTitle()
		// } else if(this.parsedData.style === 'stacker') {
		// 	//this.updateStacker()
		// }

		ScrollTrigger.addEventListener("refresh", this.resize)
	}

	setupHeadingAnimation() {
		if (this.isTitleStacker) {
			const stackerInner = this.titles.querySelector('.stacker__inner')
			this.headingTween = GSAP.fromTo(stackerInner, {
				y: '89%'
			}, {
				y: '0%',
				ease: 'none',
				scrollTrigger: {
					trigger: this.element,
					start: 'top center-=100%',
					scrub: true,
					end: 'bottom center+=170%'
				}
			})
		} else {
			this.headingSplitText = new SplitSomeText({ element: this.titlesHeading })
			this.headingTween = GSAP.fromTo(this.headingSplitText.splitChild.words, {
				y: '104%'
			}, {
				y: '0%',
				ease: 'none',
				// duration: 0.3,
				scrollTrigger: {
					trigger: this.element,
					start: 'top top',
					scrub: true,
					end: `+=${window.innerHeight * 0.5}`
				}
			})
		}
	}

	// updateStandardTitle() {

	// 	this.STInstance = ScrollTrigger.create({
	// 		trigger: this.media[0],
	// 		start: 'top center',
	// 		endTrigger: this.media[this.media.length - 1],
	// 		end: 'bottom center',
	// 		onEnter: () => {

	// 			// timeout = setTimeout(() => {
	// 			// 	canShow = true
	// 			// 	window.emitter.emit('standardTitleIn', { index: this.parsedData.index });
	// 			// }, this.globalTimeout)

	// 			//window.emitter.emit('standardTitleIn', { index: this.parsedData.index })
	// 		},
	// 		onEnterBack: () => {
	// 			//window.emitter.emit('standardTitleIn', { index: this.parsedData.index })

	// 			// timeout = setTimeout(() => {
	// 			// 	canShow = true
	// 			// 	window.emitter.emit('standardTitleIn', { index: this.parsedData.index });
	// 			// }, this.globalTimeout)
	// 		},
	// 		onLeave: () => {
	// 			// clearTimeout(timeout)
	// 			// if(canShow) {
	// 				//window.emitter.emit('standardTitleOut', { index: this.parsedData.index })
	// 			// }
	// 		},
	// 		onLeaveBack: () => {
	// 			// console.log('leave back')
	// 			//window.emitter.emit('standardTitleReset', { index: this.parsedData.index })
	// 			// clearTimeout(timeout)
	// 			// if(canShow) {
	// 			// 	window.emitter.emit('standardTitleReset', { index: this.parsedData.index })
	// 			// }
	// 		}
	// 	})
	// }

	// updateLargeTitle() {

	// 	// let timeout
	// 	// let canShow = false

	// 	this.STInstance = ScrollTrigger.create({
	// 		trigger: this.media[0],
	// 		start: 'top center',
	// 		endTrigger: this.media[this.media.length - 1],
	// 		end: 'bottom top',
	// 		onEnter: () => {
	// 			//console.log('large in')
	// 			//window.emitter.emit('largeTitleIn', { index: this.parsedData.index })

	// 			// timeout = setTimeout(() => {
	// 			// 	canShow = true
	// 			// 	window.emitter.emit('largeTitleIn', { index: this.parsedData.index });
	// 			// }, this.globalTimeout)
	// 		},
	// 		onEnterBack: () => {
	// 			//window.emitter.emit('largeTitleIn', { index: this.parsedData.index })

	// 			// timeout = setTimeout(() => {
	// 			// 	canShow = true
	// 			// 	window.emitter.emit('largeTitleIn', { index: this.parsedData.index });
	// 			// }, this.globalTimeout)
	// 		},
	// 		onLeave: () => {
	// 			//console.log('large out')
	// 			//window.emitter.emit('largeTitleOut', { index: this.parsedData.index })

	// 			// clearTimeout(timeout)
	// 			// if(canShow) {
	// 			// 	window.emitter.emit('largeTitleOut', { index: this.parsedData.index })
	// 			// }
	// 		},
	// 		onLeaveBack: () => {
	// 			// console.log('leave back')
	// 			//window.emitter.emit('largeTitleReset', { index: this.parsedData.index })

	// 			// clearTimeout(timeout)

	// 			// if(canShow) {
	// 			// 	window.emitter.emit('largeTitleReset', { index: this.parsedData.index })
	// 			// }
	// 		}
	// 	})
	// }

	// updateStacker() {

	// 	this.media.forEach((m, idx) => {
	// 		ScrollTrigger.create({
	// 			trigger: m,
	// 			start: 'top center',
	// 			end: 'bottom center',
	// 			onEnter: () => {
	// 				//console.log('enter', idx)
	// 				//window.emitter.emit('stackerTitleUpdate', { index: idx, direction: 1 })
	// 			},
	// 			onEnterBack: () => {

	// 				// if(idx < (this.media.length -1)) {
	// 				// 	window.emitter.emit('stackerTitleUpdate', { index: idx, direction: -1 })
	// 				// 	//console.log('enter back', idx)
	// 				// }
	// 			},
	// 			onLeaveBack: () => {
	// 				// if(idx == 0) {
	// 				// 	window.emitter.emit('stackerTitleUpdate', { index: -1, direction: -1 })
	// 				// 	//console.log('leave back', idx)
	// 				// }
	// 			},
	// 		})
	// 	})
	// }

	setupScrollTrigger() {
		
		this.tl = ScrollTrigger.create({
			trigger: this.element,
			start: 'top top',
			end: 'bottom center',
			pin: this.titles,
			// pinSpacing: false,
			onEnter: () => {
				this.canUpdate = true

				// this.mediaStore.map(element => {

				// 	if(element.playVideo) {
				// 		element.playVideo()
				// 	}
				// })
			},
			onEnterBack: () => {
				this.canUpdate = true
				//console.log('enter back')
				// this.mediaStore.map(element => {

				// 	if(element.playVideo) {
				// 		element.playVideo()
				// 	}
				// })
			},
			onLeave: () => {
				this.canUpdate = false
				//console.log('leave')
				// this.mediaStore.map(element => {

				// 	if(element.playVideo) {
				// 		element.pauseVideo()
				// 	}
				// })
			},
			onLeaveBack: () => {
				this.canUpdate = false
				//console.log('leave back')
				// this.mediaStore.map(element => {

				// 	if(element.playVideo) {
				// 		element.pauseVideo()
				// 	}
				// })
			}
		})
	}

	createGroup() {
		this.geometry = new PlaneGeometry(1, 1, 2, 2)

		this.mediaStore = this.media.map((element, idx) => {

			const mediaType = element.getAttribute('data-type')

			if(mediaType === 'image') {

				return new ImageObject({
					element,
					geometry: this.geometry,
					index: idx,
					style: element.getAttribute('data-style'),
					texture: this.resources.items[element.getAttribute('data-image')],
				})
			} else {
				return new VideoObject({
					element,
					geometry: this.geometry,
					index: idx,
					style: element.getAttribute('data-style'),
					video: this.resources.items[element.getAttribute('data-video')],
				})
			}

		})
	}

	resize() {
		this.mediaStore.map(element => element.resize())
	}

	update() {
		// if(this.canUpdate) {

			this.scroll.target = this.camera.position.y

			this.scroll.current = GSAP.utils.interpolate(this.scroll.current, this.scroll.target, this.scroll.lerp)

			this.mediaStore.map(element => element.update(this.scroll.current))

			// if(Math.abs(this.smoothscroll.lenis.velocity) > 100) {
				
			// }
			
		// }
	}

	unmount() {
		this.geometry.dispose()
		/**
		 * RUN THE DESTROY METHODS FOR EACH
		 */
		this.mediaStore.map(element => element.destroy())

		this.scroll.current = 0
		this.scroll.target = 0

		if(this.tl) this.tl.kill()
		if(this.STInstance) this.STInstance.kill()

		if(this.headingSplitText) this.headingSplitText.destroy()

		ScrollTrigger.removeEventListener("refresh", this.resize)
	}
}