export default [
    {
        "name": "travel-essential-1",
        "type": "image",
        "path": "\/wp-content\/uploads\/2024\/05\/Main-site-1_optim-1.png"
    },
    {
        "name": "travel-essential-insight-1",
        "type": "image",
        "path": "\/wp-content\/uploads\/2024\/05\/travel-essential-insight-1.png"
    },
    {
        "name": "travel-essential-insight-2",
        "type": "image",
        "path": "\/wp-content\/uploads\/2024\/05\/travel-essential-insight-2.png"
    },
    {
        "name": "do-more-1",
        "type": "image",
        "path": "\/wp-content\/uploads\/2024\/05\/Main-site-2_optim-2.png"
    },
    {
        "name": "do-more-insight-1",
        "type": "image",
        "path": "\/wp-content\/uploads\/2024\/05\/do-more-insight-1.png"
    },
    {
        "name": "dream-1",
        "type": "image",
        "path": "\/wp-content\/uploads\/2024\/05\/Main-site-3_optim-2.png"
    },
    {
        "name": "plan-1",
        "type": "image",
        "path": "\/wp-content\/uploads\/2024\/05\/Main-site-4_optim-1.png"
    },
    {
        "name": "pack-1",
        "type": "image",
        "path": "\/wp-content\/uploads\/2024\/05\/Main-site-5_optim-1.png"
    },
    {
        "name": "pack-2",
        "type": "image",
        "path": "\/wp-content\/uploads\/2024\/05\/Main-site-6_optim-1.png"
    },
    {
        "name": "lose-way-1",
        "type": "image",
        "path": "\/wp-content\/uploads\/2024\/05\/Main-site-7_optim-1.png"
    },
    {
        "name": "find-way-1",
        "type": "image",
        "path": "\/wp-content\/uploads\/2024\/05\/Main-site-8_optim-2.png"
    },
    {
        "name": "feast-1",
        "type": "image",
        "path": "\/wp-content\/uploads\/2024\/05\/Main-site-9_optim-1.png"
    },
    {
        "name": "splurge-1",
        "type": "image",
        "path": "\/wp-content\/uploads\/2024\/05\/Main-site-10_optim-1.png"
    },
    {
        "name": "share-memories-1",
        "type": "image",
        "path": "\/wp-content\/uploads\/2024\/05\/Main-site-11_optim-1.png"
    },
    {
        "name": "do-again-1",
        "type": "image",
        "path": "\/wp-content\/uploads\/2024\/05\/Main-site-small2_optim_small.png"
    },
    {
        "name": "do-again-2",
        "type": "image",
        "path": "\/wp-content\/uploads\/2024\/05\/Main-site-small4_optim_small.png"
    },
    {
        "name": "do-again-3",
        "type": "image",
        "path": "\/wp-content\/uploads\/2024\/05\/Main-site-small3_optim_small.png"
    },
    {
        "name": "insight-dreaming-1",
        "type": "flip",
        "path": "\/wp-content\/uploads\/2024\/05\/insight-dreaming-1-2.png"
    },
    {
        "name": "insight-dreaming-2",
        "type": "flip",
        "path": "\/wp-content\/uploads\/2024\/05\/insight-dreaming-2-2.png"
    },
    {
        "name": "dreaming-1",
        "type": "image",
        "path": "\/wp-content\/uploads\/2024\/05\/main_site_Dream2_small.png"
    },
    {
        "name": "dreaming-2",
        "type": "image",
        "path": "\/wp-content\/uploads\/2024\/05\/Main-site_dream1_small.png"
    },
    {
        "name": "planning-1",
        "type": "image",
        "path": "\/wp-content\/uploads\/2024\/05\/Main-site_plan1b_optim.png"
    },
    {
        "name": "planning-2",
        "type": "image",
        "path": "\/wp-content\/uploads\/2024\/05\/Main-site_paln2_optim_small.png"
    },
    {
        "name": "insight-planning-1",
        "type": "flip",
        "path": "\/wp-content\/uploads\/2024\/05\/insight-planning-1-1.png"
    },
    {
        "name": "insight-planning-2",
        "type": "flip",
        "path": "\/wp-content\/uploads\/2024\/05\/insight-planning-2-1.png"
    },
    {
        "name": "insight-booking-1",
        "type": "flip",
        "path": "\/wp-content\/uploads\/2024\/05\/insight-booking-1-1.png"
    },
    {
        "name": "insight-booking-2",
        "type": "flip",
        "path": "\/wp-content\/uploads\/2024\/05\/insight-booking-2-1.png"
    },
    {
        "name": "booking-1",
        "type": "image",
        "path": "\/wp-content\/uploads\/2024\/05\/Main-site_book1_optim_small.png"
    },
    {
        "name": "booking-3",
        "type": "image",
        "path": "\/wp-content\/uploads\/2024\/05\/Main-site_book2_optim_small.png"
    },
    {
        "name": "insight-preparing-1",
        "type": "flip",
        "path": "\/wp-content\/uploads\/2024\/05\/insight-preparing-1-1.png"
    },
    {
        "name": "insight-preparing-2",
        "type": "flip",
        "path": "\/wp-content\/uploads\/2024\/05\/insight-preparing-2-1.png"
    },
    {
        "name": "preparing-2",
        "type": "image",
        "path": "\/wp-content\/uploads\/2024\/05\/Main-site_prep2b_optim.png"
    },
    {
        "name": "preparing-3",
        "type": "image",
        "path": "\/wp-content\/uploads\/2024\/05\/Main-site_prep1_optim_small.png"
    },
    {
        "name": "insight-transiting-1",
        "type": "flip",
        "path": "\/wp-content\/uploads\/2024\/05\/insight-transiting-1-1.png"
    },
    {
        "name": "insight-transiting-2",
        "type": "flip",
        "path": "\/wp-content\/uploads\/2024\/05\/insight-transiting-2-1.png"
    },
    {
        "name": "transiting-3",
        "type": "image",
        "path": "\/wp-content\/uploads\/2024\/05\/Main-site_trans3_optim_small.png"
    },
    {
        "name": "transiting-1",
        "type": "image",
        "path": "\/wp-content\/uploads\/2024\/05\/Main-site_trans2_optim_small.png"
    },
    {
        "name": "insight-exploring-1",
        "type": "flip",
        "path": "\/wp-content\/uploads\/2024\/05\/insight-exploring-1-1.png"
    },
    {
        "name": "insight-exploring-2",
        "type": "flip",
        "path": "\/wp-content\/uploads\/2024\/05\/insight-exploring-2-1.png"
    },
    {
        "name": "exploring-2",
        "type": "image",
        "path": "\/wp-content\/uploads\/2024\/05\/exploring-2.png"
    },
    {
        "name": "exploring-1",
        "type": "image",
        "path": "\/wp-content\/uploads\/2024\/05\/Main-site_explore1_optim_small.png"
    },
    {
        "name": "spin",
        "type": "image",
        "path": "\/wp-content/uploads/2024/05/Icon_Spin.png"
    }
];