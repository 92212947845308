import GSAP from 'gsap'
import SplitSomeText from '../helpers/SplitTitles'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default class InsightsList {
	constructor(el) {
		this.element 			= el

		this.title 				= this.element.querySelector('.c-InsightsList__title h2')
		this.tags 				= [...this.element.querySelectorAll('.c-InsightsList__categories__tag')]
		this.animatedElements 	= []

		this.STInstance 		= null
	}

	init() {

		this.setupAnimations()
		this.setupScrollTrigger()
	}

	setupAnimations() {

		this.splitTitle = new SplitSomeText({
			element: this.title
		})

		this.animatedElements.push(...this.splitTitle.splitChild.words)
		this.animatedElements.push(...this.tags)

		this.setIndexes(this.animatedElements)
	}

	setupScrollTrigger() {
		this.STInstance = ScrollTrigger.create({
			trigger: this.element,
			start: 'top center',
			once: true,
			onEnter: () => {
				this.element.classList.add('in-view')
			}
		})
	}

	setIndexes(elements) {

		elements.forEach((el, idx)=> {
			el.style.setProperty('--index', idx)
		})
	}

	unmount() {

		if(this.STInstance) { 
			this.STInstance.kill()
		}
	
		this.splitTitle.destroy()

		this.animatedElements = []
	}
}
