import GSAP from 'gsap'
import { SplitText } from 'gsap/SplitText'

GSAP.registerPlugin(SplitText)

export default class SplitSomeText {
	constructor(config) {

		this.element 		= config.element
		this.type 			= config.type || 'standard'

		if(this.type == 'standard') {
			this.setupSplitText()
		} 

		if(this.type == 'lines') {
			this.setupSplitLines()
		}

	}

	setupSplitLines() {
		this.splitParent = new SplitText(this.element, {
			type: 'lines',
			linesClass: "parent",
		})

		this.splitChild = new SplitText(this.element, {
			type: 'words',
			wordsClass: "child",
		})

		this.setIndexes(this.splitChild.words)
	}

	setupSplitText() {

		this.splitParent = new SplitText(this.element, {
			type: 'words',
			wordsClass: "parent",
		})

		this.splitChild = new SplitText(this.element, {
			type: 'words',
			wordsClass: "child",
		})

		// this.setIndexes(this.splitChild.words)
	}

	resetWords() {
		GSAP.set(this.splitChild.words, {
			yPercent: 110
		})
	}

	showWords() {

		GSAP.to(this.splitChild.words, {
			yPercent: 0
		})
	}

	hideWords(reset) {

		GSAP.to(this.splitChild.words, {
			yPercent: -110,
			onComplete: () => {
				if(reset) {
					this.resetWords()
				}
			}
		})
	}

	setIndexes(elements) {

		elements.forEach((el, idx)=> {
			el.style.setProperty('--index', idx)
		})
	}

	destroy() {
		if(this.splitParent) this.splitParent.revert()
		if(this.splitChild) this.splitChild.revert()
	}
}
