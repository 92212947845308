import GSAP from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { Animations } from '../helpers/Animations'
import { ColorManager } from '../helpers/Colors'
import SplitSomeText from '../helpers/SplitTitles'
import ObserveElement from '../helpers/ObserveElement'

GSAP.registerPlugin(ScrollTrigger)

export default class Hero {
	constructor(el) {
		this.element 				= el

		this.arrow					= this.element.querySelector('.c-Hero__arrow__wrapper')
		this.titleFX 				= [...this.element.querySelectorAll('.title-fx')]
		this.shapeGroups			= [...this.element.querySelectorAll('.c-Hero__shapes__fx')]
		this.shapeFX 				= []
		this.titleChilds			= this.element.querySelectorAll('.child')
		this.titles 				= this.element.querySelectorAll('.entry-title')

		this.fxCount 				= this.titleFX.length - 1
		this.wrapPosition 			= GSAP.utils.wrap(-100, 100 * (this.fxCount))
		this.snap 					= GSAP.utils.snap(100)

		this.STInstance 			= null
		this.autoTitle				= null
		this.autoShape 				= null
		this.autoArrow				= null
		this.arrowTL 				= GSAP.timeline()

		this.updateTitlePositions 	= this.updateTitlePositions.bind(this)
		this.updateShapePositions 	= this.updateShapePositions.bind(this)
		this.updateArrowPosition 	= this.updateArrowPosition.bind(this)

		this.onEvent 				= this.onEvent.bind(this)

		if(window.isTouchscreen) {
			ScrollTrigger.normalizeScroll(true)
		}
	}

	init() {

		this.setTitlePositions()
		this.setShapePositions()

		this.splitTitle = new SplitSomeText({
			element: this.titles,
			type: 'lines'
		})

		setTimeout(() => {
			this.setScrollTrigger()
		}, 200)
		
		window.emitter.on('pageReady', this.onEvent)
	}

	setScrollTrigger() {
		this.STInstance = ScrollTrigger.create({
			trigger: this.element,
			start: 'top top',
			end: 'bottom top',
			onEnterBack: () => {

				this.playTweens()

				ColorManager.onChange('#34E1A1')
			},
			onLeave: () => {

				this.pauseTweens()

				if(window.scrollY > 10) {
					ColorManager.onChange('#FFFFFF')
				}
			}
		})
	}

	onEvent() {

		this.element.classList.add('active-start')
		this.titleChilds = this.element.querySelectorAll('.child')
		this.entryTitle = GSAP.to(this.titleChilds, {
			y: '0%',
			duration: Animations.timing.generic,
			ease: Animations.ease.out,
			stagger: 0.1,
			onComplete: () => { this.element.classList.add('active') }
		})
		this.autoTitle = GSAP.set(this.updateTitlePositions, {
			onRepeat: this.updateTitlePositions,
			repeat: -1,
			repeatDelay: Animations.delay.titleFx
		})

		this.autoShape = GSAP.set(this.updateShapePositions, {
			onRepeat: this.updateShapePositions,
			repeat: -1,
			repeatDelay: Animations.delay.titleFx
		})

		this.autoArrow = GSAP.set(this.updateArrowPosition, {
			onRepeat: this.updateArrowPosition,
			repeat: -1,
			repeatDelay: Animations.delay.arrow
		})

		this.observeElements = new ObserveElement()

	}

	playTweens() {
		if(this.autoTitle) this.autoTitle.play()
		if(this.autoShape) this.autoShape.play()
		if(this.autoArrow) this.autoArrow.play()
	}

	pauseTweens() {
		if(this.autoTitle) this.autoTitle.pause()
		if(this.autoShape) this.autoShape.pause()
		if(this.autoArrow) this.autoArrow.pause()
	}

	killTweens() {
		if(this.autoTitle) this.autoTitle.kill()
		if(this.autoShape) this.autoShape.kill()
		if(this.autoArrow) this.autoArrow.kill()

		if(this.arrowTL) this.arrowTL.kill()
		if(this.STInstance) this.STInstance.kill()
	}

	/**
	 * SET POSITIONS
	 */
	setTitlePositions() {

		GSAP.set(this.titleFX, {
			yPercent: i => i * 100
		})
	}

	setShapePositions() {

		this.shapeGroups.forEach(group => {

			const elements = [...group.querySelectorAll('.shape-fx')]
			
			GSAP.set(elements, {
				xPercent: i => i * 100 * -1
			})
	
			/**
			 * cache & store for animating
			 **/
			this.shapeFX.push(elements)
		})
	}

	/**
	 * UPDATE ANIMATIONS
	 */
	updateTitlePositions() {

		GSAP.to(this.titleFX, {
			duration: Animations.timing.generic,
			ease: Animations.ease.circInOut,
			overwrite: "auto",
			yPercent: (i, target) => {
				return this.snap(GSAP.getProperty(target, "yPercent") + 100 * -1)
			},    
			modifiers: {
				yPercent: this.wrapPosition
			},
		})
	}

	updateShapePositions() {

		GSAP.to(this.shapeFX, {
			duration: Animations.timing.generic,
			ease: Animations.ease.circInOut,
			overwrite: "auto",
			// rotateZ: () => '+=90deg',
			xPercent: (i, target) => {
				return this.snap(GSAP.getProperty(target, "xPercent") + 100)
			},
			modifiers: {
				xPercent: this.wrapPosition
			},
		})
	}

	updateArrowPosition() {

		this.arrowTL.to(this.arrow, {
			duration: Animations.timing.arrow,
			ease: Animations.ease.spring,
			yPercent: 100
		})

		this.arrowTL.set(this.arrow, {
			yPercent: 0
		})
	}

	unmount() {

		this.killTweens()

		if(window.isTouchscreen) {
			ScrollTrigger.normalizeScroll(false)
		}

		this.shapeFX = []

		window.emitter.off('pageReady', this.onEvent)

		// ColorManager.onChange({ backgroundColor: '' })

		ColorManager.onChange('#34E1A1')
	}
}
