export default class ObserveElement {
	constructor() {

		this.fades				= [...document.querySelectorAll('.fade')]
		this.observes 			= []

		this.fades.forEach(f => {
			this.createObserver(f)
		})
	}

	createObserver(el) {

		let hasEntered = false

		const options = {
			rootMargin: '0px 0px 0px 0px'
		};

		const ob = new IntersectionObserver(entries => {
	
			entries.forEach(entry => {
				if(entry.isIntersecting) {

					hasEntered = true
					this.addClass(el)

				} else if (hasEntered && !entry.isIntersecting) {

					this.unobserve(el, ob)
				}
			})
		}, options)

		this.observes.push(ob)

		ob.observe(el)
	}

	addClass(el) {
		el.classList.add('in-view')
	}

	unobserve(el, ob) {
		if (ob) {
			ob.unobserve(el)
			ob.disconnect()
		}
	}

	destroy() {

		this.observes.forEach(ob => {
			ob.disconnect()
		})
	}
}
