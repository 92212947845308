import GSAP from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { Animations } from '../helpers/Animations'

export default class Header {
	constructor(el) {
		this.element 			= el

		this.isExpanded			= false
		this.categoryButton 	= this.element.querySelector('.c-Header__toggle')
		this.categories 		= this.element.querySelector('.c-Header__categories__wrapper')
		this.categoryLinks 		= this.element.querySelectorAll('.c-Header__categories__wrapper a')
		this.wordmark 			= this.element.querySelector('.c-Header__logo__wordmark')

		this.STInstance 		= null

		this.stateChangeHandler = (e) => { 
			this.isExpanded  = !this.isExpanded
			this.stateChange()
		}

		this.setScrolltrigger 	= this.setScrolltrigger.bind(this)
	}
  
	init() {

		this.toggleTl = GSAP.timeline({
			paused: true
		}).fromTo(this.categoryLinks, {
			y: -30,
			autoAlpha: 0,
			pointerEvents: 'none'
		}, {
			y: 0,
			autoAlpha: 1,
			duration: 0.2,
			pointerEvents: 'auto',
			stagger: 0.01,
			ease: 'power2.out',
			overwrite: true
		})

		this.categoryButton.addEventListener('click', this.stateChangeHandler)

		window.emitter.on('pageReady', this.setScrolltrigger)
	}

	setScrolltrigger() {

		this.STInstance = ScrollTrigger.create({
			trigger: document.querySelector('main'),
			start: 'top top-=200px',
			onEnter: () => {
				this.hideWordmark()
			},
			onLeaveBack: () => {
				this.showWordmark()
			}
		})
	}

	hideWordmark() {

		GSAP.to(this.wordmark, {
			duration: Animations.timing.wordmark,
			ease: Animations.ease.generic,
			yPercent: -100,
			autoAlpha: 0,
			onComplete: () => {

				GSAP.set(this.wordmark, {
					display: 'none'
				})
			}
		})
	}

	showWordmark() {

		GSAP.set(this.wordmark, {
			display: 'unset'
		})

		GSAP.to(this.wordmark, {
			duration: Animations.timing.wordmark,
			ease: Animations.ease.generic,
			yPercent: 0,
			autoAlpha: 1
		})
	}

	stateChange() {
		if(this.isExpanded === true) {
			this.showCat()
		} else {
			this.hideCat()
		}
	}

	showCat() {
		this.categoryButton.classList.add('active')
		this.toggleTl.timeScale(1).play()
	}

	hideCat() {
		this.categoryButton.classList.remove('active')
		this.toggleTl.timeScale(1.5).reverse()
	}

	unmount() {
		if (this.toggleTl) { this.toggleTl.kill() }
		if(this.STInstance) { this.STInstance.kill() }

		this.categoryButton.removeEventListener('click', this.stateChangeHandler)
		window.emitter.off('pageReady', this.setScrolltrigger)
	}
}
