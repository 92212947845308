import GSAP from 'gsap'
import imagesLoaded from 'imagesloaded'
import { Utils } from '../helpers/Utils'
import { Animations } from '../helpers/Animations'
import { ColorManager } from '../helpers/Colors'

export default class Preloader {
	constructor(el) {
		this.element 				= el

		this.imagery 				= [...this.element.querySelectorAll('img')]
		this.imgLoad 				= new imagesLoaded(this.imagery)

		this.imagesLoadedCallback 	= this.imagesLoadedCallback.bind(this)

		this.imageWrapper 			= this.element.querySelector('.c-Preloader__imagery')
		this.logoWrapper 			= this.element.querySelector('.c-Preloader__logo')
		this.titleWrapper 			= this.element.querySelector('.c-Preloader__titles')
		this.titleItems 			= [...this.element.querySelectorAll('.c-Preloader__titles__item')]
		this.background 			= this.element.querySelector('.c-Preloader__background')

		this.snap 					= GSAP.utils.snap(100)
		this.tl 					= GSAP.timeline()

		this.sourcesLoaded 			= false
		this.animationInFinished 	= false
        this.canAnimate 			= false

		this.init()
	}

	init() {

		this.setTitlePositions()

		this.imgLoad.on( 'done', this.imagesLoadedCallback)

		window.emitter.on('sourcesLoaded', () => {
            this.sourcesLoaded = true

            if (this.animationInFinished) {
                this.canAnimate = true
                this.animationOut()
            }
        })
	}

	setTitlePositions() {

		GSAP.set(this.titleItems, {
			yPercent: i => (i + 1) * 100
		})

		/**
		 * SO WE DON'T GET A FLASH OF UNSTYLED CONTENT
		 * A.K.A - 'FOUC'
		 */
		GSAP.set(this.titleItems, {
			visibility: 'visible'
		})
	}

	updateTitlePositions() {

		GSAP.to(this.titleItems, {
			duration: Animations.timing.preloader,
			ease: Animations.ease.powerFour,
			yPercent: (i, target) => {
				return this.snap(GSAP.getProperty(target, "yPercent") + 100 * -1)
			}
		})
	}

	animationIn() {

		this.imagery.forEach((img, idx) => {

			this.tl.to(img, {
				duration: Animations.timing.preloader,
				ease: Animations.ease.powerFour,
				scale: 1,
				onStart: () => {
					this.updateTitlePositions()
				},
				onComplete: () => {

					if(idx === (this.imagery.length - 1)) {

						this.animationInFinished = true

						if (this.sourcesLoaded) {
							this.canAnimate = true
							this.animationOut()
						}
					}
				}
			})
		})
	}

	animationOut() {

		this.tl.to(this.titleWrapper, {
			delay: 1,
			duration: Animations.timing.preloader,
			autoAlpha: 0,
		})

		// this.tl.to([this.logoWrapper, this.titleWrapper], {
		// 	delay: 1,
		// 	duration: Animations.timing.preloader,
		// 	autoAlpha: 0,
		// 	stagger: Animations.stagger.reversePreloader
		// })

		this.tl.to(this.imagery, {
			duration: Animations.timing.preloader,
			ease: Animations.ease.back,
			y: () => window.innerHeight * 0.6 + (this.imagery[0].offsetHeight * 0.5),
			stagger: Animations.stagger.reversePreloader
		}, '<')

		this.tl.to(this.background, {
			duration: Animations.timing.generic,
			ease: Animations.ease.powerFour,
			scaleY: 0,
			delay: 0.5,
			onComplete: () => {
				this.cleanup()
			}
		}, '-=0.6')
	}

	imagesLoadedCallback() {

		this.animationIn()
	}

	cleanup() {
		this.element.remove()
		Utils.startScroll()
		window.emitter.emit('pageReady')
	}

	unmount() {

		if(this.tl) this.tl.kill()
		
	}
}
