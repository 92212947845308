import 'lazysizes'
import GSAP from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

GSAP.registerPlugin(ScrollTrigger)

/**
 * MITT EVENT EMITTER
 */
import mitt from 'mitt'
window.emitter = mitt()

/**
 * THREE.JS
 */
import { Scene } from 'three'
import Resources from './webgl/Resources.js'
import Camera from './webgl/Camera.js'
import Renderer from './webgl/Renderer.js'
import sources from './webgl/sources.js'

/**
 * GLOBALS
 */
import SmoothScroll from './globals/SmoothScroll'
import CreateBarba from './globals/CreateBarba'
import PageTransition from './globals/PageTransitions'
import Preloader from './globals/Preloader'

/**
 * HELPERS
 */
import ScriptComponentLoader from './helpers/ScriptComponentLoader'
import BarbaBodyClass from './helpers/BarbaBodyClass'
import Sizes from './helpers/Sizes'
import { Utils } from './helpers/Utils'
import { ColorManager } from './helpers/Colors'

/**
 *  GLOBAL COMPONENTS
 */
import Example from './components/c-Example'
import BrandCategoryList from './components/c-BrandCategoryList'
import Header from './components/c-Header'
import Titles from './components/c-Titles.js'
import TimelineY from './components/c-TimelineY'
import TimelineYGroup from './components/c-TimelineYGroup'
import TimelineZ from './components/c-TimelineZ'
import TimelineZGroup from './components/c-TimelineZGroup'
import Hero from './components/c-Hero'
import KeyFindings from './components/c-KeyFindings'
import Brands from './components/c-Brands'
import HeroSingle from './components/c-HeroSingle'
import BarGraph from './components/c-BarGraph'
import InsightsList from './components/c-InsightsList'
import FlexibleParagraph from './components/c-FlexibleParagraph'
import CircleGraph from './components/c-CircleGraph'

// KEY 'string' must appear as it does in the PHP class file
const componentMap = {
	'brandCategoryList': BrandCategoryList,
	'header': Header,
	'titles': Titles,
	'timelineY': TimelineY,
	'timelineYGroup': TimelineYGroup,
	'timelineZ': TimelineZ,
	'timelineZGroup': TimelineZGroup,
	'hero': Hero,
	'keyFindings': KeyFindings,
	'brands': Brands,
	'heroSingle': HeroSingle,
	'barGraph': BarGraph,
	'insightsList': InsightsList,
	'flexibleParagraph': FlexibleParagraph,
	'circleGraph': CircleGraph
}

let instance = null

export default class Experience {
	constructor(config) {

		if(instance) {
			return instance
		}

		instance 					= this
		this.canvas 				= config.canvas
		this.videoContainer 		= config.videoContainer
		this.scriptRefs 			= []

		window.isTouchscreen 		= Utils.isTouchDevice()
		this.viewportHeight			= Utils.setElementViewportHeight()

		if (window.isTouchscreen) {
			document.documentElement.classList.add('touch')
		} else {
			document.documentElement.classList.add('desktop')
		}

		this.sizes 					= new Sizes()

		this.scriptComponentLoader 	= new ScriptComponentLoader()
		this.bobyClassHelper		= new BarbaBodyClass()
		this.barbaRef 				= new CreateBarba().barba

		if(!window.isTouchscreen) {
			this.smoothScroll 		= new SmoothScroll()
		}

		/** 
		 * ADDS CLASS TO BODYTAG TO STOP SCROLL WHILE SITE IS LOADING
		 * REMOVED IN THE PRELOADER COMPONENT WHEN SITE IS READY
		 **/
		this.debugMode = document.body.classList.contains('debug--true')

		if(!this.debugMode) {
			Utils.stopScroll()
		}

		/**
		* WEBGL
		*/
		window.objectsToRaycast 	= []
		this.resources				= new Resources(sources, this.videoContainer)
		this.scene 					= new Scene()
		this.camera					= new Camera()
		this.renderer				= new Renderer()
		this.canUpdate 				= true

		if(!this.debugMode) {
			this.preloader 			= new Preloader(document.querySelector('.c-Preloader'))
		}
		this.pageTransition 		= new PageTransition({ element: document.querySelector('.c-PageTransition') })

		/**
		 * WAIT UNTIL SOURCES ARE LOADED
		 * TO RUN APP
		 */
		window.emitter.on('sourcesLoaded', () => {

			GSAP.ticker.add(() => {
				this.update()
			})

			if(!window.isTouchscreen) {
				window.emitter.on('windowResized', () => { 
					this.resize() 
				})
			}

			if(window.isTouchscreen) {
				ScrollTrigger.addEventListener("refresh", this.resize.bind(this))
			}


			this.createBarba()
			this.init()
		})


		// console.info("%cstudiojasonbooth.com", "color:#fff;background:#222;font-family:sans-serif;font-size:1.25em;border-radius:2em;padding: 0.1em 1em")
	}

	init() {

		this.scriptRefs = [] // Clear existing scriptRefs, if any

		this.scriptComponentLoader.getAndLoad(this.scriptRefs, componentMap)
	}

	initOnNewPage(data) {

		return new Promise((resolve) => {

			this.scriptRefs = [] // Clear existing scriptRefs

			this.scriptComponentLoader.filterAndLoad(this.scriptRefs, componentMap, data)

			resolve()
		})
	}

	createBarba() {

		this.barbaRef.init({
			schema: {
				prefix: 'data-transition',
			},
			// debug: true,
			transitions: [
				{
					name: 'default-transition',
					once: (data) => {
	
						if(this.debugMode) {

							this.pageTransition.once()
						}

					},
					beforeLeave: async (data) => {

						await new Promise(resolve => {
							this.pageTransition.beforeLeave(resolve, data)
						})

					},
					leave: async (data) => {

						await new Promise(resolve => {
							this.pageTransition.leave(resolve)
						})

						this.scriptComponentLoader.unmountComponents(this.scriptRefs, data)
						
					},
					beforeEnter: async (data) => {

						await new Promise(resolve => {
							this.pageTransition.beforeEnter(resolve, data)
						})

						if(data.next.namespace == 'page-home') {
							this.canUpdate = true

						} else {
							this.canUpdate = false
						}

						this.bobyClassHelper.getClasses(data)
					},
					after: async (data) => {

						await this.initOnNewPage(data)

						this.pageTransition.after()
					}
				}
			]
		})
	}

	resize() {

		this.viewportHeight			= Utils.setElementViewportHeight()

		if(this.scriptRefs) {
			this.scriptRefs.forEach(component => {
					if(component.resize) {
						component.resize()
					}
			})
		}

		this.renderer.resize()
		this.camera.resize()
	}

	update() {
		if(this.canUpdate) {
			this.renderer.update()
		}

		if(this.scriptRefs) {
			this.scriptRefs.forEach(component => {
					if(component.update) {
						component.update()
					}
			})
		}
	}
}

document.addEventListener('DOMContentLoaded', () => {
	new Experience({
		videoContainer: document.querySelector('.c-VideoContainer'),
		canvas: document.querySelector('.c-Webgl__canvas')
	})
})
