import  { MeshBasicMaterial, Mesh, Group } from 'three'
import GSAP from 'gsap'
import { Animations } from '../helpers/Animations'

import Experience from '../main'
// import vertex from './shaders/vertex.glsl'
// import fragment from './shaders/fragment.glsl'

export default class FlipObject {
	constructor(config) {
		this.experience 	= new Experience()
		this.sizes 			= this.experience.sizes
		this.scene 			= this.experience.scene
		this.camera 		= this.experience.camera.instance

		this.element 		= config.element
		this.geometry 		= config.geometry
		this.textureFront	= config.textureFront
		this.textureBack	= config.textureBack
		this.group 			= config.group
		this.spinIcon		= config.spinIcon
		this.time 			= 0
		this.canUpdate 		= false

		this.meshGroup 		= new Group()

		this.spinTL 		= GSAP.timeline({
			defaults: {
				repeat: -1,
				duration: 1.4,
				repeatDelay: 1
			}
		})

		this.spinTL.pause()

		this.createMaterial()
		this.createMeshes()
		this.resize()
		this.createSpinAnimation()

		this.resize 		= this.resize.bind(this)

		window.emitter.on('pageReady', this.resize)
	}

	createMaterial() {

		this.materialFront = new MeshBasicMaterial({
			transparent: true,
			map: this.textureFront
			// uniforms: {
			// 	uTexture: { value: this.textureFront },
			// 	uOpacity: { value:  0 },
			// },
			// vertexShader: vertex,
			// fragmentShader: fragment,
		})

		this.materialFront.opacity = 0

		// this.materialFront.depthWrite = false

		this.materialBack = new MeshBasicMaterial({
			transparent: true,
			map: this.textureBack
			// uniforms: {
			// 	uTexture: { value: this.textureBack },
			// 	uOpacity: { value:  0 },
			// },
			// vertexShader: vertex,
			// fragmentShader: fragment,
		})

		// this.materialBack.depthWrite = false

		this.spinIcon = new MeshBasicMaterial({
			transparent: true,
			map: this.spinIcon
			// uniforms: {
			// 	uTexture: { value: this.spinIcon },
			// 	uOpacity: { value:  0 },
			// },
			// vertexShader: vertex,
			// fragmentShader: fragment,
		})

		this.spinIcon.opacity = 0
		this.spinIcon.alphaTest = 0.1
	}

	createMeshes() {
		this.meshBack 		= new Mesh(this.geometry, this.materialBack)
		this.meshFront 		= new Mesh(this.geometry, this.materialFront)
		this.spinIconMesh 	= new Mesh(this.geometry, this.spinIcon)

		this.meshBack.rotation.y 		= Math.PI
		this.spinIconMesh.position.z 	= 1

		this.meshGroup.add(this.meshBack, this.meshFront, this.spinIconMesh)

		window.objectsToRaycast.push(this.meshFront, this.meshBack)

		this.group.add(this.meshGroup)
	}

	offsetZ(offset) {
		this.meshGroup.position.z = offset
	}

	posX() {
		this.meshGroup.position.x = (-this.sizes.width * 0.5) + (this.meshFront.scale.x * 0.5) + this.bounds.left

		this.spinIconMesh.position.x = (this.meshFront.scale.x * 0.5 ) - (this.spinIconMesh.scale.x * 0.5)
	}

	posY(y = 0) {
		this.meshGroup.position.y = ((this.sizes.height * 0.5) - (this.meshFront.scale.y * 0.5) - this.bounds.top) + y
	}

	scale() {
		this.meshFront.scale.set(this.bounds.width, this.bounds.height, 1)
		this.meshBack.scale.set(this.bounds.width, this.bounds.height, 1)
		this.spinIconMesh.scale.set(this.bounds.height, this.bounds.height, 0)
	}

	show() {

		GSAP.to([this.materialFront, this.spinIcon, this.materialBack], {
			opacity: 1
		})

		// GSAP.to(this.materialBack.uniforms.uOpacity, {
		// 	value: 1
		// })

		// GSAP.to([this.materialFront, this.spinIcon], {
		// 	opacity: 1
		// })

		// this.canUpdate = true

		this.startSpin()
	}

	hide() {

		// GSAP.to(this.materialBack.uniforms.uOpacity, {
		// 	value: 0
		// })

		GSAP.to([this.materialFront, this.spinIcon, this.materialBack], {
			opacity: 0
		})

		// this.canUpdate = false
		this.stopSpin()
	}

	createSpinAnimation() {

		this.spinTL.fromTo(this.spinIconMesh.rotation, {
			z: 0
		},{
			ease: Animations.ease.circInOut,
			duration: Animations.timing.generic,
			z: -Math.PI
		})
	}

	startSpin() {

		this.spinTL.play()
	}

	stopSpin() {

		this.spinTL.pause()
	}
	resize() {
		this.bounds 		= this.element.getBoundingClientRect() 

		this.scale()
	 	this.posX()
		this.posY(this.camera.position.y)
	}

	update() {

	}
	
	destroy() {
		this.textureFront.dispose()
		this.textureBack.dispose()
		this.spinIcon.dispose()

		this.materialFront.dispose()
		this.materialBack.dispose()
		this.spinIcon.dispose()
	
		this.scene.remove(this.meshFront, this.meshBack, this.spinIconMesh)

		this.spinTL.kill()

		window.emitter.off('pageReady', this.resize)
	}
}