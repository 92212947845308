import  { ShaderMaterial, Mesh } from 'three'
import GSAP from 'gsap'

import Experience from '../main'
import vertex from './shaders/vertex.glsl'
import fragment from './shaders/fragment.glsl'
import { MediaAnimations } from './MediaAnimations'

export default class Media {
	constructor(config) {
		this.experience 	= new Experience()
		this.sizes 			= this.experience.sizes
		this.scene 			= this.experience.scene
		this.camera 		= this.experience.camera.instance

		this.element 		= config.element
		this.geometry 		= config.geometry
		this.texture 		= config.texture
		this.style 			= config.style
		this.hasShow 		= config.hasShow || false
		this.group 			= config.group || false
		this.index 			= config.index

		this.rotationOffset = 0

		this.createMaterial()
		this.createMesh()
		this.resize()

		this.resize 			= this.resize.bind(this)

		window.emitter.on('pageReady', this.resize)
	}

	createMaterial() {

		this.material = new ShaderMaterial({
			// wireframe: true
			transparent: true,
			uniforms: {
				uTexture: { value: this.texture },
				uOpacity: { value:  this.hasShow ? 0 : 1 },
			},
			vertexShader: vertex,
			fragmentShader: fragment,
		})

	}

	skew(lerpCamera) {

		MediaAnimations.skew(this.mesh, lerpCamera, this.sizes, this.rotationOffset, this.index)
	}

	arc(lerpCamera) {
		MediaAnimations.arc(this.mesh, lerpCamera, this.sizes, this.rotationOffset, this.bounds)
	}

	stacker(lerpCamera) {
		MediaAnimations.stacker(this.mesh, lerpCamera, this.sizes, this.rotationOffset)
	}

	createMesh() {
		this.mesh 	= new Mesh(this.geometry, this.material)

		if(this.group) {
			this.group.add(this.mesh)
		} else {
			this.scene.add(this.mesh)
		}
	}

	offsetZ(offset) {
		this.mesh.position.z = offset
	}

	posX() {
		this.mesh.position.x = (-this.sizes.width * 0.5) + (this.mesh.scale.x * 0.5) + this.bounds.left
	}

	posY(y = 0) {
		this.mesh.position.y = ((this.sizes.height * 0.5) - (this.mesh.scale.y * 0.5) - this.bounds.top) + y
	}

	scale() {
		this.mesh.scale.set(this.bounds.width, this.bounds.height, 1)
	}

	show() {
		GSAP.to(this.material.uniforms.uOpacity, {
			value: 1
		})
	}

	hide() {
		GSAP.to(this.material.uniforms.uOpacity, {
			value: 0
		})
	}

	resize() {
		this.bounds 		= this.element.getBoundingClientRect() 

		this.scale()
	 	this.posX()
		this.posY(this.camera.position.y)
	}

	update(lerpCamera) {
		if(this.style === 'skew') {
			this.skew(lerpCamera)
		} else if(this.style === 'arc') {
			this.arc(lerpCamera)
		} else if(this.style === 'stacker') {
			this.stacker(lerpCamera)
		}
	}
	
	destroy() {
		this.material.dispose()
		this.texture.dispose()
		this.scene.remove(this.mesh)
		window.emitter.off('pageReady', this.resize)
	}
}