const Animations = {

	ease: {
		spring: "elastic.out(0.5, 0.2)",
		expoInOut: "expo.inOut",
		generic: "expo.out",
		circInOut: "circ.inOut",
		circOut: "circ.out",
		sin: "sin.inOut",
		powerTwo: "power2.inOut",
		powerFour: "power4.inOut",
		back: 'back.in(3)',
		out: 'power2.out'
	},

	timing: {
		preloader: 0.75,
		generic: 1,
		titleFx: 1,
		wordmark: 0.5,
		arrow: 1.8,
		fast: 0.3,
		mouseMove: 0.5
	},

	delay: {
		generic: 1,
		titleFx: 1.5,
		arrow: 3
	},

	stagger: {
		reversePreloader: -0.06, 
		generic: 0.5,
		mouseMove: 0.05
	}
}

export { Animations }