import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default class Sizes  {
	constructor() {
		this.width 		= window.innerWidth
		this.height 	= window.innerHeight
		this.pixelRatio = Math.min(window.devicePixelRatio, 2)

		window.addEventListener('resize', () => { 
			this.debounceResize(() => this.handleResize(), 50) 
		})
	}

	debounceResize(callback, delay) {
		// Clear any existing resize timeout
		clearTimeout(this.resizeTimeout)
	
		// Set a new timeout to execute the callback after the delay
		this.resizeTimeout = setTimeout(() => {
			if (typeof callback === 'function') {
				callback()
			}
		}, delay)
	}

	handleResize() {
		this.width 		= window.innerWidth
		this.height 	= window.innerHeight
		this.pixelRatio = Math.min(window.devicePixelRatio, 2)

		window.emitter.emit('windowResized') 

		if(!window.isTouchscreen) {
			ScrollTrigger.refresh()
		}
	}
}