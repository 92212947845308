import  { PlaneGeometry, Group } from 'three'
import GSAP from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

// GSAP.registerPlugin(ScrollTrigger)

import Experience from '../main'
import ImageObject from '../webgl/ImageObject'
import VideoObject from '../webgl/VideoObject'
import FlipObject from '../webgl/FlipObject'

export default class TimelineZGroup {
	constructor(el) {
		this.element 	= el

		this.experience 	= new Experience()
		this.resources		= this.experience.resources
		this.camera 		= this.experience.camera.instance
		this.scene 			= this.experience.scene
		this.sizes 			= this.experience.sizes

		this.index 			= Number(this.element.getAttribute('data-index'))
		this.media			= [...this.element.querySelectorAll('.c-TimelineZ__item')]

		this.hasShow 		= false
		this.group 			= new Group()

		this.resize 		= this.resize.bind(this)
	}

	init() {

		this.createGroup()
		this.onEvent()

		ScrollTrigger.addEventListener("refresh", this.resize)
	}

	createGroup() {
		this.geometry = new PlaneGeometry(1, 1, 1, 1)

		this.mediaStore = this.media.map((element, idx) => {

			const mediaType = element.getAttribute('data-type')

			if(mediaType === 'image') {

				return new ImageObject({
					element,
					geometry: this.geometry,
					style: element.getAttribute('data-style'),
					group: this.group,
					hasShow: true,
					texture: this.resources.items[element.getAttribute('data-image')],
				})
			} else if(mediaType === 'video') {

				return new VideoObject({
					element,
					geometry: this.geometry,
					style: element.getAttribute('data-style'),
					group: this.group,
					video: this.resources.items[element.getAttribute('data-video')],
				})
			} else if (mediaType === 'flip') {

				const images = [...element.querySelectorAll('.c-TimelineZ__item__flip')]

				return new FlipObject({
					element,
					geometry: this.geometry,
					group: this.group,
					style: element.getAttribute('data-style'),
					textureFront: this.resources.items[images[0].getAttribute('data-image')],
					textureBack: this.resources.items[images[1].getAttribute('data-image')],
					spinIcon: this.resources.items['spin'],
				})
			}

		})

		this.scene.add(this.group)

		/** OFFSET THE POSITIONS TO CONICIDE WITH IT'S INDEX IN THE DOM */
		//this.group.position.z = -((window.innerHeight) + (this.index * (window.innerHeight * 4)))

		// this.mediaStore.map((element, idx) => {
		// 	if(idx === 0) {
		// 		element.offsetZ(-this.sizes.height * 1)
		// 	}

		// 	if(idx === 1) {
		// 		element.offsetZ(-this.sizes.height * 2)
		// 	}

		// 	if(idx === 2) {
		// 		element.offsetZ(-this.sizes.height * 1.5)
		// 	}
		// })

		this.group.state = 'false'
	}

	onEvent() {
		window.emitter.on('sectionActive', (data) => {

			if(data.index === this.index) {
				this.mediaStore.map(element => { 
					element.show() 

					// if(element.playVideo) {
					// 	element.playVideo()
					// }
				})
			}
		})

		window.emitter.on('sectionInactive', (data) => {

			if(data.index === this.index) {
				this.mediaStore.map(element => { 
					element.hide() 

					// if(element.pauseVideo) {
					// 	element.pauseVideo()
					// }
				})
			}

		})
	}

	resize() {
		this.mediaStore.map(element => element.resize())

		this.group.position.z = -((window.innerHeight) + (this.index * (window.innerHeight * 3)))

		this.mediaStore.map((element, idx) => {
			if(idx === 0) {
				element.offsetZ(-this.sizes.height * 0.5)
			}

			if(idx === 1) {
				element.offsetZ(-this.sizes.height * 1.5)
			}

			if(idx === 2) {
				element.offsetZ(-this.sizes.height * 1)
			}
		})
	}

	update() {
		//this.mediaStore.map(element => element.update())
	}

	unmount() {
		this.geometry.dispose()
		/**
		 * RUN THE DESTROY METHODS FOR EACH
		 */
		this.mediaStore.map(element => element.destroy())

		// window.emitter.off('windowResized', this.resize)
		ScrollTrigger.removeEventListener("refresh", this.resize)

		this.scene.remove(this.group)
	}
}