import GSAP from 'gsap'
import SplitSomeText from '../helpers/SplitTitles'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { Animations } from '../helpers/Animations'
import ObserveElement from '../helpers/ObserveElement'

ScrollTrigger.normalizeScroll(false)

export default class HeroSingle {
	constructor(el) {
		this.element 	= el

		this.title 		= this.element.querySelector('.c-HeroSingle__title')
		this.splitTitle = null
		this.onEvent 	= this.onEvent.bind(this)
		this.arrow		= this.element.querySelector('.c-HeroSingle__arrow__wrapper')

		/**
		 * MOUSEMOVE INTERACTION
		 * SETUP FOR DESKTOP
		 */
		if(!window.isTouchscreen) {

			this.dataOptions		= this.element.getAttribute('data-options')
			this.parsedData 		= JSON.parse(this.dataOptions)
		
			this.trailLength 		= Number(this.parsedData.trail)
			this.mouseElements 		= [...this.element.querySelectorAll('.mouse-element')]
			this.threshold 			= 100
			this.mouse 				= { x: 0, y: 0 }
			this.lastPosition 		= { x: 0, y: 0 }// To track the last placed item position
			this.currentIndex 		= 0
			this.currentItem 		= null
			this.canTrail 			= false
			this.handleMouseMove 	= this.handleMouseMove.bind(this)

			this.animationTimeout
			this.isAnimating 		= false
	
			if(this.trailLength < this.mouseElements.length) {
				this.canTrail = true
			}
	
			this.shuffled = this.shuffleArray(this.mouseElements)
		}

		this.arrowTL 				= GSAP.timeline()
		this.updateArrowPosition 	= this.updateArrowPosition.bind(this)
	}

	init() {

		this.splitTitle = new SplitSomeText({
			element: this.title,
			type: 'lines'
		})

		window.emitter.on('pageReady', this.onEvent)
	}

	updateArrowPosition() {

		this.arrowTL.to(this.arrow, {
			duration: Animations.timing.arrow,
			ease: Animations.ease.spring,
			yPercent: 100
		})

		this.arrowTL.set(this.arrow, {
			yPercent: 0
		})
	}

	onEvent() {

		this.element.classList.add('active')

		/** MOUSEMOVE STUFF **/
		if(!window.isTouchscreen) {
			if(this.mouseElements.length > 0 && !window.isTouchscreen) {
				this.element.addEventListener('mousemove', this.handleMouseMove)
			}
		}

		this.autoArrow = GSAP.set(this.updateArrowPosition, {
			onRepeat: this.updateArrowPosition,
			repeat: -1,
			repeatDelay: Animations.delay.arrow
		})

		this.observeElements = new ObserveElement()
	}

	distanceFromLast(x, y) {
		return Math.hypot(x - this.mouse.x, y - this.mouse.y)
	}

	handleMouseMove(e) {

		clearTimeout(this.animationTimeout)

		if(this.distanceFromLast(e.pageX, e.pageY) > this.threshold) {

			this.currentItem = this.shuffled[this.currentIndex % this.mouseElements.length]
			const trailIndex = this.shuffled[(this.currentIndex - this.trailLength) % this.mouseElements.length]

			 // Calculate the angle using the last placed item's position
			 const diffX = e.pageX - this.lastPosition.x
			 const diffY = e.pageY - this.lastPosition.y
			 const angle = Math.atan2(diffY, diffX)



			/**
			 * UPDATE CURRENT MOUSE POS
			 * THE ABOVE IF STATEMENT WILL UPDATE EVERYTIME THE 'THRESHOLD' IS MET
			 */
			this.activate(this.currentItem, e.pageX, e.pageY, angle)

			if(this.canTrail && trailIndex) {
				trailIndex.dataset.status = 'inactive'
			}

            // Update the last position to the current position
            this.lastPosition.x = e.pageX
            this.lastPosition.y = e.pageY

			/** UPDATE CURRENT INDEX */
			this.currentIndex++
		}

		this.animationTimeout = setTimeout(() => this.hideElements(), 1500)
	}

	hideElements() {

		const activeElements = this.shuffled.filter(element => element.getAttribute('data-status') === 'active')

		GSAP.to(activeElements, {
			duration: Animations.timing.mouseMove,
			ease: Animations.ease.back,
			y: '+=200px',
			stagger: Animations.stagger.mouseMove,
			opacity: 0
		})
	}

	activate(element, x, y, angle) {
		element.style.zIndex 	= this.currentIndex
		element.dataset.status 	= 'active'

		this.mouse.x = x
		this.mouse.y = y

		// const offsetX = 20 * Math.cos(angle)
        // const offsetY = 20 * Math.sin(angle)

		const TL = GSAP.timeline()

		TL.set(element, {
			opacity: 1,
			yPercent: 0,
			scale: 0.5,
			x: x - (element.clientWidth * 0.5),
			y: y - (element.clientHeight * 0.5)
		})

		TL.to(element, {
			duration: 1,
			scale: 1,
			ease: Animations.ease.spring,
			// x: `+=${offsetX}px`,
			// y: `+=${offsetY}px`
		})
	}

	shuffleArray(array) {

		return array
		  .map(value => ({ value, sort: Math.random() }))
		  .sort((a, b) => a.sort - b.sort)
		  .map(({ value }) => value)
	}

	cleanup() {
		this.splitTitle.destroy()

		if(this.arrowTL) this.arrowTL.kill()
		if(this.autoArrow) this.autoArrow.kill()
	}

	unmount() {

		this.cleanup()

		if(!window.isTouchscreen) {
			this.element.removeEventListener('mousemove', this.handleMouseMove)
			this.mouse 	= {}
		}
		
		window.emitter.off('pageReady', this.onEvent)

		this.observeElements.destroy()
	}
}
