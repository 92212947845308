import GSAP from 'gsap'

class Colors {
	constructor() {
		this.colors = {
			'trip-green': '#34E1A1',
			'white': '#FFFFFF',
		}
	}

	onChange(color) {
		// GSAP.set(document.body, {
		// 	backgroundColor,
		// })

		document.body.style.setProperty('--color-state', color)
	}

	set({ backgroundColor }) {
		GSAP.set(document.body, {
			backgroundColor
		})
	}
 }

export const ColorManager = new Colors()