import GSAP from 'gsap'

const MediaAnimations = {

	skew(mesh, camera, sizes, rotationOffset, index) {

		rotationOffset = (mesh.position.y - camera) * 0.5

		mesh.position.z = GSAP.utils.mapRange(
			-sizes.height * 1,
			sizes.height * 1,
			200,
			-200,
			rotationOffset
		)

		if(index % 2 === 0) {

			mesh.rotation.z = GSAP.utils.mapRange(
				sizes.height * 1,
				-sizes.height * 1,
				Math.PI * 0.06,
				-Math.PI * 0.06,
				rotationOffset
			)
			mesh.rotation.x = GSAP.utils.mapRange(
				sizes.height * 1,
				-sizes.height * 1,
				Math.PI * 0.1,
				-Math.PI * 0.1,
				rotationOffset
			)
		} else {

			mesh.rotation.z = GSAP.utils.mapRange(
				sizes.height * 1,
				-sizes.height * 1,
				Math.PI * 0.09,
				-Math.PI * 0.09,
				-rotationOffset
			)
			mesh.rotation.x = GSAP.utils.mapRange(
				sizes.height * 1,
				-sizes.height * 1,
				Math.PI * 0.1,
				-Math.PI * 0.1,
				-rotationOffset
			)
		}

	},

	arc(mesh, camera, sizes, rotationOffset, bounds) {

		rotationOffset = mesh.position.y - camera

		mesh.position.x = (-sizes.width * 0.5) + (mesh.scale.x * 0.5) + bounds.left
		mesh.position.x += (Math.cos((rotationOffset / sizes.height) * Math.PI * 0.3) * sizes.height - sizes.height) 

		mesh.rotation.z = GSAP.utils.mapRange(
			-sizes.height * 1,
			sizes.height * 1,
			Math.PI * 0.2,
			-Math.PI * 0.2,
			-rotationOffset
		)
	},

	stacker(mesh, camera, sizes, rotationOffset) {
		rotationOffset = (mesh.position.y - camera) * 0.5

		mesh.position.z = GSAP.utils.mapRange(
			-sizes.height * 1,
			sizes.height * 1,
			400,
			-400,
			Math.abs(rotationOffset)
		)
		mesh.rotation.z = GSAP.utils.mapRange(
			sizes.height * 1,
			-sizes.height * 1,
			Math.PI * 0.2,
			-Math.PI * 0.2,
			rotationOffset
		)
	}
}

export { MediaAnimations }