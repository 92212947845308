import  { Raycaster, Vector2 } from 'three'
import GSAP from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

GSAP.registerPlugin(ScrollTrigger)

import Experience from '../main'
import { ColorManager } from '../helpers/Colors'
import { Animations } from '../helpers/Animations'
import RaycastHandler from '../webgl/RaycastHandler'

export default class TimelineZ {
	constructor(el) {
		this.element 	= el

		this.experience 			= new Experience()
		this.resources				= this.experience.resources
		this.camera 				= this.experience.camera.instance
		this.scene					= this.experience.scene

		this.tl 					= null
		this.STInstance				= null

		this.images 				= [...this.element.querySelectorAll('img')]
		this.sections 				= [...this.element.querySelectorAll('.c-TimelineZ__section')]
		this.titles					= [...this.element.querySelectorAll('.c-TimelineZ__titles span')]
		this.categorySelections		= [...this.element.querySelectorAll('.c-TimelineZ__categories__selection')]
		this.categoryWrapper		= this.element.querySelector('.c-TimelineZ__categories')
		this.pinWrapper 			= this.element.querySelector('.c-TimelineZ__pinner')

		this.sectionColors 			= []
		this.raycastHandler 		= new RaycastHandler()
		this.onMouseMove 			= this.onMouseMove.bind(this)
		this.onClick 				= this.onClick.bind(this)
		this.triggers 				= []
	}

	init() {

		this.setSectionScrollTrigger()
		this.setupTitles()
		this.setupCategories()
		this.setupScrollTrigger()
	}

	onClick() {
		this.raycastHandler.handleMouseClick()
	}

	onMouseMove(e) {
		this.raycastHandler.handleMouseMove(e)
	}

	setupScrollTrigger() {

		this.tl = GSAP.timeline({paused: true})

		this.tl.to(this.camera.position, {
			ease: 'none',
			z: () => `-=${((window.innerHeight * (this.sections.length * 3)))}`,
		})

		this.STInstance = ScrollTrigger.create({
			trigger: this.element,
			start: 'top top',
			endTrigger: this.sections[this.sections.length - 1],
			end: 'bottom top',
			pin: this.pinWrapper,
			scrub: true,
			invalidateOnRefresh: true,
			pinSpacing: false,
			animation: this.tl,
			onEnter: () => {
				this.addEvents()
				this.showCategories()
			},
			onEnterBack: () => {
				this.addEvents()
				this.showCategories()

				ScrollTrigger.refresh()
			},
			onLeave: () => {
				this.removeEvents()
				this.hideCategories()
			},
			onLeaveBack: () => {
				this.removeEvents()
				this.hideCategories()

				ScrollTrigger.refresh()
			}
		})
	}

	setSectionScrollTrigger() {

		this.sections.forEach((section, idx) => {
			const dataIndex = Number(section.getAttribute('data-index'))
			const dataColor = section.getAttribute('data-color')

			// fill with hex codes
			this.sectionColors.push(dataColor)

			const sectionTrigger = ScrollTrigger.create({
				trigger: section,
				start: 'top top',
				onEnter: () => {

					window.emitter.emit('sectionActive', {
						index: dataIndex
					})

					if(dataIndex > 0) {
						this.updateTitles(idx)
						this.updateCategories(dataIndex)
					}

					ColorManager.onChange(this.sectionColors[dataIndex])
				},
				onEnterBack: () => {
					//console.log('enter back')
					window.emitter.emit('sectionActive', {
						index: dataIndex
					})

					this.updateTitles(idx)
					this.updateCategories(dataIndex)

					ColorManager.onChange(this.sectionColors[dataIndex])
				},
				onLeave: () => {
					window.emitter.emit('sectionInactive', {
						index: dataIndex
					})

					if(dataIndex === this.sections.length - 1) {

						ColorManager.onChange('#ffffff')
					}

				},
				onLeaveBack: () => {
					//console.log('leave back Z')

					window.emitter.emit('sectionInactive', {
						index: dataIndex
					})

					if(dataIndex == 0) {

						ColorManager.onChange('#ffffff')
					}
				}
			})

			this.triggers.push(sectionTrigger)
		})
	}

	setupTitles() {
		/** SET TO 120% FOR TESTING
		 * BASICALLY NEED TO SET THE LINE-HEIGHT OF THE SPANS BETTER
		 * SO WE'RE JUST TRANSLATING THE ELEMENT 100% OF IT'S HEIGHT
		 */
		GSAP.set(this.titles, {
			yPercent: i => i * 100
		})
	}

	updateTitles(idx) {

		GSAP.to(this.titles, {
			duration: Animations.timing.fast,
			ease: Animations.ease.powerTwo,
			overwrite: true,
		    yPercent: i => (-idx * 100) + i * 100,
      	})
	}

	setupCategories() {
		this.categorySelections[0].classList.add('active')
	}

	showCategories() {
		this.categoryWrapper.classList.add('is-active')
	}

	hideCategories() {
		this.categoryWrapper.classList.remove('is-active')
	}

	updateCategories(currentIndex) {

		this.categorySelections.forEach((cat, idx) => {
			cat.classList[idx == currentIndex ? 'add' : 'remove' ]('active')
		})
	}

	addEvents() {
		this.element.addEventListener('click', this.onClick)
		window.addEventListener('mousemove', this.onMouseMove)
	}

	removeEvents() {

		document.removeEventListener('click', this.onClick)
		window.removeEventListener('mousemove', this.onMouseMove)
		window.emitter.off('pageReady', this.onEvent)
	}

	cleanup() {

		this.sectionColors = []

		if(this.tl) {
			this.tl.kill()
			this.tl = null
		}

		this.triggers.forEach(t => {
			t.kill()
		})

		this.triggers = []

		if(this.STInstance) this.STInstance.kill()

		GSAP.set(this.camera.position, { z: window.innerHeight })

		this.raycastHandler.destroy()

		window.objectsToRaycast = []
	}

	unmount() {

		this.removeEvents()
		this.cleanup()
	}
}